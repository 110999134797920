import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';

async function polyfillNumberFormat(locale) {
    try {
        const polyfillLocale = shouldPolyfillLocale();

        if (polyfillLocale) {
            await import('@formatjs/intl-locale/polyfill');
        }

        const unsupportedLocale = shouldPolyfillNumberFormat(locale);

        if (!unsupportedLocale) {
            return;
        }

        // Load the polyfill 1st BEFORE loading data
        await import('@formatjs/intl-numberformat/polyfill-force');
        await import(`@formatjs/intl-numberformat/locale-data/${unsupportedLocale}`).catch(
            (e) => {},
        );
    } catch (e) {}
}

document.addEventListener('DOMContentLoaded', () => {
    polyfillNumberFormat(CBS.Registry.region.locale);
});
